<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col">
        <vs-button
          class="mt-2"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          @click="handleCreate()"
          >Create</vs-button
        >
      </div>
    </div>
    <div class="vx-row mt-10 mb-6">
      <div class="vx-col sm:w-1/5 w-full">
        <span>Warehouse</span>
      </div>
      <div class="vx-col sm:w-4/5 w-full">
        <div class="vx-col w-1/2">
          <template>
            <Warehouse
                :multiple="true"
                placeholder="ALL Warehouse"
                @selected="(val) => {
                    this.selectedWarehouse = val
                }"
            ></Warehouse>
          </template>
        </div>
      </div>
    </div>

    <div class="vx-row w-1/2 mb-6 mt-3">
      <div class="vx-col">
        <vs-button
          @click="onClickBtnFilter"
          size="medium"
          color="primary"
          type="gradient"
          icon-pack="feather"
          icon="icon-search"
          title="Search"
        >
          Filter
        </vs-button>
      </div>
    </div>

    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @search="handleSearch"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <!-- <vs-th
                ><vs-checkbox
                  v-model="selectAll"
                  @input="handleSelectAll"
                />ALL</vs-th
              > -->
        <vs-th></vs-th>
        <vs-th sort-key="code">Code</vs-th>
        <vs-th sort-key="name">Warehouse</vs-th>
        <vs-th sort-key="name">Request Date</vs-th>
        <vs-th sort-key="name">Count Type</vs-th>
        <vs-th sort-key="name"># of SKU</vs-th>
        <vs-th sort-key="name">TOTAL UOM/Qty [System]</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-checkbox
                v-model="cartStockOpname"
                :vs-value="tr.id"
                color="success"
                @input="handleClickCheckBox()"
              />
              <vx-tooltip text="Edit">
                <vs-button
                  class="ml-2"
                  size="small"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-edit"
                  @click="HandleEdit(tr.id)"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Cancel">
                <vs-button
                  class="ml-2"
                  size="small"
                  color="danger"
                  icon-pack="feather"
                  @click="handleDelete(tr.id)"
                  icon="icon-x-circle"
                />
              </vx-tooltip>
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            {{ data[indextr].code }}
          </vs-td>
          <vs-td :data="data[indextr].warehouse_code">
            {{ data[indextr].warehouse_code }} - {{ data[indextr].warehouse_name }}
          </vs-td>
          <vs-td :data="data[indextr].request_date">
            {{ data[indextr].request_date }}
          </vs-td>
          <vs-td :data="data[indextr].count_type">
            {{ data[indextr].count_type }}
          </vs-td>
          <vs-td :data="data[indextr].of_sku">
            {{ data[indextr].of_sku }}
          </vs-td>
          <vs-td :data="data[indextr].total_uom">
            {{ data[indextr].total_uom }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
    <div class="vx-row">
      <div class="vx-col sm:w-6/12 w-full">
        <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()"
          >Process</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Warehouse from "@/components/Warehouse.vue";
export default {
  components: {
    Warehouse,
  },

  data() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowFormatted = tomorrow.toISOString().split("T")[0];
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      exportID: [],
      IDwasGenerate: [],
      showingData: true,
      selectAll: false,
      date: tomorrowFormatted,
      cartStockOpname: [],
      optionWarehouse: [],
      selectedWarehouse: [],
      IDItem: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },

    getData() {

      const warehouseIds = this.selectedWarehouse.map(warehouse => {
                return warehouse.id;
            });

      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-opname/table", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            //request_date: this.date,
            status: 1,
            warehouse_id: warehouseIds,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleClickCheckBox() {
      console.log(">>>>iiii", this.cartStockOpname);
    },
    onClickBtnFilter() {
      let params = {
        ...this.table,
      };

      if (this.date) {
        params.date = moment(this.date).format("YYYY-MM-DD");
      }
      this.getData(params);
    },

    SubmitForm() {
      console.log(">>>>>ini submit", this.cartStockOpname);
      if (this.cartStockOpname.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/stock-opname/process", {
          stock_opname_id: this.cartStockOpname,
          status: "2",
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    handleCreate() {
      this.$router.push({
        name: "sof-create",
        query: { action: "Create" },
      });
      this.id = null;
      this.detail = true;
    },
    HandleEdit(id) {
      console.log("ini test", id);
      this.$router.push({
        name: "sof-edit",
        query: { id: id, action: "Edit" },
      });
      this.id = id;
      this.detail = true;
    },
    handleDelete(id) {
      console.log(id)
      this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Cancel`,
          text: "Are you sure to cancel this data?",
          accept: () => {
            this.$vs.loading();
            this.$http
            .get(`/api/wms/v1/stock-opname/cancel/${id}`)
            .then((resp) => {
                if (resp.code == 200) {
                    this.getData()
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            });
          }
      });

    },
  },
  watch: {
    detail() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>
