<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="Review">
        <New></New>
      </vs-tab>
      <vs-tab label="Release">
        <Release></Release>
      </vs-tab>
      <vs-tab label="Process">
        <Process></Process>
      </vs-tab>
      <vs-tab label="Compile">
        <Compile></Compile>
      </vs-tab>
      <vs-tab label="SAF">
        <SAF></SAF>
      </vs-tab>
      <vs-tab label="Close">
        <Close></Close>
      </vs-tab>
      <vs-tab label="Export">
        <Export></Export>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import New from "./_tab/new-tab.vue";
import Release from "./_tab/release-tab.vue";
import Process from "./_tab/process-tab.vue";
import Compile from "./_tab/compile-tab.vue";
import SAF from "./_tab/saf-tab.vue";
import Close from "./_tab/close-tab.vue";
import Export from "./_tab/Export.vue";
export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    New,
    Release,
    Process,
    Compile,
    SAF,
    Close,
    Export,
  },
  data() {
    return {
      title: "Stock Opname",
    };
  },
};
</script>
