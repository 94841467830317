<template>
  <div>
    <div class="vx-col mb-6 w-1/2">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>SOF ID</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="sofData"
            :options="optionSOFID"
            :show-labels="false"
            :searchable="true"
            :multiple="false"
            :allow-empty="true"
            :group-select="true"
            :internal-search="false"
            @search-change="handleSearchSOFID"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            :custom-label="customLabelOptionSOFID"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Count Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input
            class="w-full"
            v-model="date"
            type="date"
            v-validate="'required'"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-row ml-4">
            <vs-button
              class="m-2"
              color="primary"
              icon-pack="feather"
              icon="icon-search"
              @click="onClickBtnFilter"
            >
              Filter
            </vs-button>
            <vs-button
              class="m-2"
              color="primary"
              icon-pack="feather"
              icon="icon-trash-2"
              @click="onClickBtnDeleteFilter"
              >Clear</vs-button
            >
          </div>
        </div>
      </div>
    </div>

    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @search="handleSearch"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <!-- <vs-th
                    ><vs-checkbox
                      v-model="selectAll"
                      @input="handleSelectAll"
                    />ALL</vs-th
                  > -->
        <vs-th></vs-th>
        <vs-th sort-key="code">SOF ID</vs-th>
        <vs-th sort-key="saf_code">SAF ID</vs-th>
        <vs-th sort-key="name">Warehouse Code</vs-th>
        <vs-th sort-key="name">Counting Date</vs-th>
        <vs-th sort-key="name">Count Type</vs-th>
        <vs-th sort-key="name">Last Count SKU</vs-th>
        <vs-th sort-key="name">System Data</vs-th>
        <vs-th sort-key="name">Counting Data</vs-th>
        <vs-th sort-key="name">SAF Value</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-button
                class="ml-2"
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-eye"
                @click="HandleShow(tr.id)"
              ></vs-button>
              <vs-button
                class="ml-2"
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-x"
                @click="HandleCancel(tr.id)"
              ></vs-button>
              <vs-button
                class="ml-2"
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-check"
                @click="HandleExecute(tr)"
              ></vs-button>
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            {{ data[indextr].code }}
          </vs-td>
          <vs-td :data="data[indextr].saf_code">
            {{ data[indextr].saf_code }}
          </vs-td>
          <vs-td :data="data[indextr].warehouse_code">
            {{ data[indextr].warehouse_code }}
          </vs-td>
          <vs-td :data="data[indextr].request_date">
            {{ data[indextr].request_date }}
          </vs-td>
          <vs-td :data="data[indextr].count_type">
            {{ data[indextr].count_type }}
          </vs-td>
          <vs-td :data="data[indextr].of_sku">
            {{ data[indextr].of_sku }}
          </vs-td>
          <vs-td :data="data[indextr].total_uom">
            {{ data[indextr].total_uom }}
          </vs-td>
          <vs-td :data="data[indextr].total_uom_real">
            {{ data[indextr].total_uom_real }}
          </vs-td>
          <vs-td :data="data[indextr].delta">
            {{ data[indextr].delta }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},

  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      exportID: [],
      IDwasGenerate: [],
      showingData: true,
      selectAll: false,
      date: moment().format("YYYY-MM-DD"),
      cartStockOpname: [],
      IDItem: [],
      sofData: { Code: "", id: 0 },
      optionSOFID: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },

    getData(params) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-opname/table", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            request_date: params.date ? params.date : this.date,
            status: 4,
            id: params.id,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    onClickBtnFilter() {
      console.log("this.sofData", this.sofData);
      let params = {
        ...this.table,
      };
      if (this.sofData) {
        params.id = this.sofData.id;
      }
      console.log("params", this.sofData);

      if (this.date) {
        params.date = moment(this.date).format("YYYY-MM-DD");
      }

      console.log("params??", params);

      this.getData(params);
    },
    onClickBtnDeleteFilter() {
      let params = {
        ...this.table,
      };

      if (params.zoneID) {
        params.zoneID = 0;
      }

      if (params.date) {
        params.date = moment().format("YYYY-MM-DD");
      }
      this.getData(params);
    },
    selectItem(item) {
      let check = this.cartStockOpname.find(
        (cartItem) => cartItem.deliveryPlanId === item.id
      );

      if (!check) {
        this.cartStockOpname.push({
          warehouseId: item.warehouse_id,
          deliveryPlanId: item.id,
          deliveryPlanCode: item.transport_delivery_code,
          delvieryPlanDate: this.date,
          totalVolume: item.total_volume,
          totalWeight: item.total_weight,
          methodPlan: item.picking_method,
          zoneID: item.zone_id,
          deliveryOrder: item.delivery_order,
        });
      } else {
        this.cartStockOpname = this.cartStockOpname.filter(
          (cartItem) => cartItem.deliveryPlanId !== item.id
        );
      }
    },

    async getOptionSOFID(params) {
      this.$vs.loading();
      await this.$http
        .get("/api/wms/v1/stock-opname/all-SOF-ID", {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSOFID = resp.data;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Zones option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    async handleSearchSOFID(query, id) {
      let optionSOFID = await this.getOptionSOFID({
        search: query,
        length: 10,
      });
      if (optionSOFID.data.records != null) {
        // add all option
        optionSOFID.data.records.unshift({
          id: 0,
          Code: "All",
        });
        this.optionSOFID = optionSOFID.data.records;
      } else {
        this.optionSOFID = [];
      }
    },
    customLabelOptionSOFID({ Code }) {
      return `${Code}`;
    },

    HandleCancel(id) {
      if (this.cartStockOpname.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/stock-opname/process", {
          id: id,
          status: "5",
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    HandleShow(id) {
      this.$router.push({
        name: "sof-show",
        query: { id: id, action: "Show" },
      });
      this.id = id;
      this.detail = true;
    },
    HandleExecute(item) {
      if (this.cartStockOpname.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/stock-opname/process", {
          id: item.id,
          status: "5",
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getData();
            this.CreateSAF(item);
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    CreateSAF(item) {
      if (this.cartStockOpname.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/stock-opname/create-SAF", {
          stock_opname_id: item.id,
          warehouse_id: item.warehouse_id,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
  },
  watch: {
    detail() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
  mounted() {
    this.getOptionSOFID();
  },
};
</script>

<style>
.vs-button .icon {
  background: none;
}
</style>
